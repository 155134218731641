import { AutodeskProductLogo } from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'

const ProductLogo = ({
  blok,
  blok: {
    backgroundColor,
    fontSize,
    hasPrecedingLogo,
    isH1ForPage,
    productImage,
    productName,
    textAlign,
  },
  foregroundColor,
  isNav,
  isProductCard,
}) => {
  return (
    <SbEditable content={blok}>
      <AutodeskProductLogo
        backgroundColor={backgroundColor}
        color={foregroundColor}
        fontSize={fontSize}
        hasPrecedingLogo={hasPrecedingLogo}
        isH1ForPage={isH1ForPage}
        isNav={isNav}
        isProductCard={isProductCard}
        productLogoImage={productImage}
        productLogoName={productName}
        textAlign={textAlign}
      />
    </SbEditable>
  )
}

export default ProductLogo
